
import { Vue, Component, Prop } from 'vue-property-decorator';
import { SelectOption } from '@/components/form/form.types';
import AzureLoginService, { AzUser } from '@/components/Auth/auth.service';
import AzLogin from '@/components/AzLogin.vue';
import GButton from '@/components/gsk-components/GskButton.vue';
import GSelect from '@/components/gsk-components/GskSelect.vue';
import HelpTooltip from '../HelpTooltip.vue';
import { organisationAllowList } from '@/constants/index';

@Component({
  components: {
    AzLogin,
    GButton,
    GSelect,
    HelpTooltip,
  },
})
export default class CicdCiForm extends Vue {
  @Prop(Boolean) loading!: boolean;

  azService = new AzureLoginService();
  azLoggedIn = false;
  azLoading = false;
  adoOrgs: SelectOption[] = [];
  adoOrg = '';
  noAdoOptions = false;
  handleAzLogin(user: AzUser) {
    this.$log('az login');
    this.azLoading = true;
    this.azLoggedIn = true;
    this.azService
      .getOrganizations(user.id)
      .then(orgs => this.azService.canCreateProjectsForOrgs(orgs.map(org => org.AccountName)))
      .then(orgsPermissions => {
        const orgAllowList = new Set(organisationAllowList);
        return Object.keys(orgsPermissions)
          .map(org => ({
            label: org,
            value: org, // need org name for api requests
          }))
          .filter(
            orgOption => orgsPermissions[orgOption.value] && orgAllowList.has(orgOption.value),
          );
      })
      .then(orgOptions => {
        this.adoOrgs = orgOptions;
        this.noAdoOptions = !orgOptions.length;
      })
      .finally(() => {
        this.azLoading = false;
      });
  }
  handleAzLogout() {
    this.azLoggedIn = false;
    this.adoOrg = '';
  }
  get submitDisabled() {
    return this.adoOrg === '' || this.loading;
  }
}
