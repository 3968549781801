
import { Vue, Component, Prop } from 'vue-property-decorator';
import GitHubLogin from '@/components/GitHubLogin.vue';
import { SelectOption } from '@/components/form/form.types';
import { searchListings } from '@/api/listings.api';
import { EnumsModule } from '@/store/modules/enums.module';
import gitHubLoginService from '@/components/github-auth/gitHubLoginService';
import GButton from '@/components/gsk-components/GskButton.vue';
import GSelect from '@/components/gsk-components/GskSelect.vue';
import GTextfield from '@/components/gsk-components/GskTextfield.vue';
import HelpTooltip from '../HelpTooltip.vue';

@Component({
  components: {
    GitHubLogin,
    GButton,
    GSelect,
    GTextfield,
    HelpTooltip,
  },
})
export default class CicdRepoForm extends Vue {
  @Prop({ required: true, type: Object }) value!: { templateId: string; repoName: string };
  @Prop(Boolean) valid!: boolean;
  @Prop(Boolean) repoNameValid!: boolean;

  ghSerivce = gitHubLoginService;

  templateOptions: SelectOption[] = [];
  get templateName() {
    return this.templateOptions.find(t => t.value === this.template)?.label;
  }

  get template() {
    return this.value.templateId;
  }

  set template(val) {
    this.$emit('input', { ...this.value, templateId: val });
  }

  get repoName() {
    return this.value.repoName;
  }
  set repoName(val) {
    this.$emit('update:repoNameValid', true);
    this.$emit('input', { ...this.value, repoName: val });
  }

  get nextDisabled() {
    return this.template === '';
  }

  getTemplateOptions() {
    return searchListings({
      filter: {
        typeId: EnumsModule.enums.listingType.CICD.id,
      },
      resultsPerPage: 100,
      pageNumber: 1,
    }).then(({ data }) => {
      const res = data.searchResults.types[0]?.listings?.map(listing => {
        const id = listing.listingId + '';
        if (this.$route.query.tlid === id) {
          this.template = id;
        }
        return {
          value: id,
          label: listing.listingName,
          description: listing.listingDescription,
        };
      });
      if (res) {
        this.templateOptions = res;
        return true;
      } else {
        return false;
      }
    });
  }

  ghLoggedIn = false;
  ghLoading = false;
  handleGhLogin() {
    this.ghLoading = true;
    this.getTemplateOptions()
      .then(() => {
        this.ghLoggedIn = true;
      })
      .finally(() => {
        this.ghLoading = false;
      });
  }
  handleGhLogout() {
    this.ghLoggedIn = false;
    this.template = '';
    this.templateOptions = [];
  }
}
