
import { Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { filter,get, omit } from 'lodash';
import { RawLocation } from 'vue-router';
import { ValidationObserver } from 'vee-validate';
import FullScreenForm from '@/components/FullScreenForm.vue';
import GButton from '@/components/gsk-components/GskButton.vue';
import { addCicdToProject, createProject } from '@/api/projects.api';
import Form from '@/components/mixins/formChecks';
import { RouteNames, TextfieldInfo, AUTH_SRC, Platforms } from '@/constants';
import GTextfield from '@/components/gsk-components/GskTextfield.vue';
import { addAnalyticsRouteParams, ClickData } from '@/analytics';
import { openErrorSnackbar, openSnackbar } from '@/utils/components';
import GAnalytics from '@/components/GAnalytics';
import { SelectField, TextField, RadioField, AutoCompleteField } from '@/components/form/form.types';
import ValidatedForm from '@/components/form/ValidatedForm.vue';
import { EnumsModule } from '@/store/modules/enums.module';
import { CreateProjectRequestBody } from '@/types/projects.types';
import { FeatureFlagsModule } from '@/store/modules/feature-flags.module';
import AzureLoginService from '@/components/Auth/auth.service';
import Grid from '@/components/grid/Grid';
import GridCell from '@/components/grid/GridCell';
import { UITab } from '@/types';
import * as types from '@/components/form/form.types';
import NavigationList from '@/components/NavigationList.vue';
import CicdCiForm from '@/components/new-project/CicdCiForm.vue';
import CicdRepoForm from '@/components/new-project/CicdRepoForm.vue';
import { getQuery, slugify, cleanOutSpecialCharAndTags } from '@/utils/routing';
import gitHubLoginService from '@/components/github-auth/gitHubLoginService';
import { ProjectDetailsModule } from '@/store/modules/project-details.module';
import HelpTooltip from '@/components/HelpTooltip.vue';
import invariant from 'ts-invariant';
import { Octokit } from '@octokit/rest';
import * as API from '@/api/projects.api';
import { ProcessEnvsModule } from '@/store/modules/process-envs.module';

type ProjectFields = [
  TextField<'name'>,
  TextField<'description'>,
  SelectField<'bu'>,
  TextField<'sbu'>,
  AutoCompleteField<'budgetingSource'>,
  RadioField<'platformType'>,
  RadioField<'oauthType'>,
];
type CicdLinks = [UITab<'details'>, UITab<'repo'>, UITab<'ci'>] | [UITab<'repo'>, UITab<'ci'>];

@Component({
  components: {
    FullScreenForm,
    GButton,
    GTextfield,
    GAnalytics,
    ValidatedForm,
    ValidationObserver,
    CicdCiForm,
    CicdRepoForm,
    Grid,
    GridCell,
    NavigationList,
    HelpTooltip,
  },
})
export default class ProjectNewView extends mixins(Form) {
  $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  };

  private loading = false;
  private azLoginService = new AzureLoginService();
  ghService = gitHubLoginService;

  get projectName(): string {
    return this.form[0].value;
  }
  get projectDescription(): string {
    return this.form[1].value;
  }

  repoModel = {
    templateId: '',
    repoName: '',
  };
  get ghValid(): boolean {
    return this.repoModel.templateId !== '' && this.repoModel.repoName !== '';
  }

  repoNameValid = true;
  async validateRepoName(): Promise<boolean> {
    this.repoModel.repoName = slugify(this.repoModel.repoName);
    const n = this.repoModel.repoName;
    if (n.length < 1 || n.length > 100) {
      this.repoNameValid = false;
      return false;
    }
    if (!/^[a-z0-9-.]+$/.test(n)) {
      this.repoNameValid = false;
      return false;
    }
    try {
      // weird typescript type issue here
      // probably need to update octokit, no time to test it now though
      // TS2339: Property 'repos' does not exist on type 'never'.
      await (this.ghService.octokit as any)?.repos.get({
        owner: 'gsk-tech',
        repo: this.repoModel.repoName,
      });
      this.repoNameValid = false;
      return false;
    } catch (e) {
      this.repoNameValid = true;
      return true;
    }
  }

  form: ProjectFields = generateForm();

  get repoDisabled(): boolean {
    if (this.isPreselectedProject) {
      return false;
    }
    return this.form[0].value && this.form[1].value && this.form[2].value
      ? this.$refs.observer?.flags?.invalid ?? true
      : true;
  }

  isPreselectedProject = false;

  get links(): CicdLinks {
    const out: CicdLinks = [
      {
        text: 'Project details',
        key: 'details',
      },
      {
        text: 'Repository template',
        key: 'repo',
        disabled: this.repoDisabled,
      },
      {
        text: 'CI environment',
        key: 'ci',
        disabled: !this.ghValid || this.repoDisabled,
      },
    ];

    if (this.isPreselectedProject) {
      out.shift();
    }

    return out;
  }
  activeLink: UITab = this.links[0];
  updateActiveLink(link: UITab): void {
    if (this.repoModel.repoName === '') {
      this.repoModel.repoName = slugify(this.projectName);
    }
    if (this.activeLink.key === 'repo') {
      this.validateRepoName().then(valid => {
        if (valid) {
          this.activeLink = link;
        }
      });
    } else {
      this.activeLink = link;
    }
  }

  setDefaultAuthSource(authVal: string) {
    const oAuthSelected = Object.keys(Platforms).find(
      currentKey => Platforms[currentKey].readableName === authVal,
    ) as any;
    const key = Platforms[oAuthSelected].platformVal;
    this.form[6].options = Object.keys(AUTH_SRC)
      .filter(currentKey => currentKey == 'PING_OAUTH' || currentKey === key)
      .map(currentKey => AUTH_SRC[currentKey].readableName);
    this.form[6].value = AUTH_SRC['PING_OAUTH'].readableName;
  }

  updateFormData(field: any, value: any, newVal: any) {
    if(value.key === 'platformType') {
      this.setDefaultAuthSource(newVal)
    }
  }

  get isDetailsStep(): boolean {
    return this.activeLink.key === 'details';
  }
  get isCiStep(): boolean {
    return this.activeLink.key === 'ci';
  }

  get isRepoStep(): boolean {
    return this.activeLink.key === 'repo';
  }

  async handleRepoNext(): Promise<void> {
    const valid = await this.validateRepoName();
    if (valid) {
      this.nextStep();
    }
  }

  async handleRepoBack(): Promise<void> {
    const valid = await this.validateRepoName();
    if (valid) {
      this.prevStep();
    }
  }

  handleButton(): void {
    const done = this.nextStep();
    if (done) {
      // submit
    }
  }

  nextStep(): boolean {
    const { key } = this.activeLink;
    const lastIndex = this.links.length - 1;
    const i = this.links.findIndex(l => l.key === key);
    if (i !== -1) {
      if (i >= lastIndex) {
        return true;
      } else {
        this.updateActiveLink(this.links[i + 1]);
      }
    }
    return false;
  }

  prevStep(): void {
    const { key } = this.activeLink;
    const firstIndex = 0;
    const i = this.links.findIndex(l => l.key === key);
    if (i !== -1) {
      if (i > firstIndex) {
        this.updateActiveLink(this.links[i - 1]);
      }
    }
  }

  // setOAuthOptions() {
  //   this.form[5].options = 
  // }

  async created(): Promise<void> {
    const pid = getQuery(this, 'pid', { toNumber: false, toArray: false });
    const lid = getQuery(this, 'tlid', { toNumber: false, toArray: false });
    if (pid) {
      this.loading = true;
      this.isPreselectedProject = true;
      try {
        await ProjectDetailsModule.getProject(pid);
        this.repoModel.repoName = slugify(ProjectDetailsModule.projectDetails.projectName);
      } catch (e) {
        openErrorSnackbar.call(this, `Could not load project: ${e.message}`);
        if (lid) {
          this.$router.safeBack({
            name: RouteNames.ListingConnectCicd,
            params: {
              listingId: lid,
            },
          });
        } else {
          this.$router.safeBack({
            name: RouteNames.Listings,
          });
        }
      } finally {
        this.loading = false;
      }
      this.updateActiveLink(this.links[0]);
    }
    this.setDefaultAuthSource(this.form[5].value);
  }

  get isCicd(): boolean {
    return this.$route.params.type === 'cicd';
  }
  get isApi(): boolean {
    return this.$route.params.type === 'api';
  }

  // Hides OAuth selection group on new Cicd project screen
  @Watch('isCicd', { immediate: true })
  onCicdChange(): void {
    // OAuth selection should be hidden already unless both ping and Azure ad are enabled
    if (FeatureFlagsModule.pingEnabled && FeatureFlagsModule.azureADEnabled) {
      this.form[6].hide = this.isCicd;
    }
  }

  get analyticsData(): ClickData {
    return {
      clickTarget: 'new-project-form-submit-button',
      projectName: this.projectName,
      projectDescription: this.projectDescription,
    };
  }

  async save(sendAnalytics: () => void, adoOrganization?: string): Promise<void> {
    this.loading = true;
    sendAnalytics();
    let data: CreateProjectRequestBody;
    if (this.isPreselectedProject) {
      const p = ProjectDetailsModule.projectDetails;
      data = {
        projectName: cleanOutSpecialCharAndTags(p.projectName),
        projectDescription: p.projectDescription,
        businessUnit: p.businessUnitId,
        businessSubUnit: p.businessSubUnit,
        oauthType: Object.keys(AUTH_SRC).find(
          currentKey => AUTH_SRC[currentKey].readableName === p.oauthType,
        ),
      };
    } else {
      data = {
        projectName: this.form[0].value,
        projectDescription: this.form[1].value,
        businessUnit: this.form[2].value,
        businessSubUnit: this.form[3].value || undefined,
        ...(get(this.form[4],'value.value','')!=='' && { budgetingSource: omit(get(this.form[4],'value',{}),['label','value']) }),
        oauthType: Object.keys(AUTH_SRC).find(
          currentKey => AUTH_SRC[currentKey].readableName === this.form[6].value,
        ),
      };
    }

    if (adoOrganization) {
      const cicdFormData: CreateProjectRequestBody['cicd'] = {
        gitHubToken: this.ghService.getToken() || '',
        templateListingId: this.repoModel.templateId,
        repoName: this.repoModel.repoName,
        adoOrganization,
        adoToken: '',
        testServices: [],
      };
      // to make sure we have the freshest token:
      // try to refresh existing token
      // if that fails set the Azure Login component back to logged out state
      try {
        if (process.env.VUE_APP_CLIENT_API_MOCKS !== 'true') {
          cicdFormData.adoToken = await this.azLoginService.refreshAdoToken();
        }
      } catch (e) {
        this.$root.$emit('azLogout');
        openErrorSnackbar.call(this, 'Your Azure session has expired, please sign in again');
        return;
      }
      data.cicd = cicdFormData;
    } else {
      data.api = true;
    }
    let oAuthSelected: string | undefined;
    if (this.isPreselectedProject) {
      oAuthSelected = Object.keys(AUTH_SRC).find(
            currentKey => AUTH_SRC[currentKey].readableName === ProjectDetailsModule.projectDetails.oauthType,
          );
    } else {
      oAuthSelected = Object.keys(AUTH_SRC).find(
            currentKey => AUTH_SRC[currentKey].readableName === this.form[6].value,
          );
    }
    const pid = getQuery(this, 'pid', { toNumber: false, toArray: false });
    const f = this.isPreselectedProject && pid ? addCicdToProject.bind(null, pid) : createProject;
    return f(data)
      .then(({ data: { projectId } }) => {
        this.$router
          .push({
            name: this.isCicd ? RouteNames.ProjectCiCd : RouteNames.ProjectDetails,
            params: addAnalyticsRouteParams(
              { id: projectId.toString(), oAuthSelected: oAuthSelected? oAuthSelected: ''},
              {
                projectId: projectId,
                projectName: this.projectName,
              },
            ),
          })
          .catch(e => {
            // we don't care about this error, but need to suppress it for cypress
            this.$logger.warn(e);
          });
      })
      .catch(e => {
        this.$log('error', e);
        openSnackbar.call(this, 'Server Error: Could not create new project', {
          type: 'error',
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  back(): void {
    this.$router.safeBack(this.closeRoute);
  }

  get closeRoute(): RawLocation {
    return {
      name: RouteNames.NewProject,
    };
  }

  get disabled(): boolean {
    return this.loading;
  }
}

function budgetingSourceTooltipMessage() {
    return ProcessEnvsModule.processEnvs.budgetingSourceTooltipText;
}

function generateForm(): ProjectFields {
  return [
    {
      type: 'text',
      key: 'name',
      label: TextfieldInfo.nameLabel,
      value: '',
      attrs: {
        placeholder: TextfieldInfo.namePlaceholder,
        maxlength: '50',
        'data-testid': 'new-project-input-name',
      },
      required: true,
      validation: {
        rules: {
          required: true,
        },
      },
    },
    {
      type: 'long-text',
      key: 'description',
      label: TextfieldInfo.descriptionLabel,
      value: '',
      attrs: {
        placeholder: TextfieldInfo.descriptionPlaceholder,
        maxlength: '255',
        'data-testid': 'new-project-input-description',
      },
      // helpText: 'Enter a short description of your project',
      required: true,
      validation: {
        rules: {
          required: true,
        },
      },
    },
    {
      type: 'select',
      key: 'bu',
      label: 'Business Unit',
      value: '',
      options: EnumsModule.businessUnitFormOptions,
      required: true,
      attrs: {
        'data-cy': 'business',
      },
      enhanced: true,
      validation: {
        rules: {
          required: true,
        },
      },
    },
    {
      type: 'text',
      key: 'sbu',
      label: 'Business Sub-unit',
      value: '',
      attrs: {
        placeholder: 'Enter a business sub-unit',
        maxlength: '100',
        'data-cy': 'sub-business',
      },
    },
    {
      type: 'autocomplete',
      key: 'budgetingSource',
      label:'',
      customLabel: 'Budgeting Source (Cost Center or WBS for Capex Projects)',
      customLabelWithIcon: 'question_mark',
      customLabelIconHoverText :  budgetingSourceTooltipMessage(),
      value: {
        label: '',
        value: ''
      },
      hide:!FeatureFlagsModule.budgetingSourceEnabled,
      choices: [],
      async getChoices(query: string) {
        const choices = await API.getBudgetingSourceList(query);
        return choices.data.map(res => ({
          label: `${res.id} - ${res.name}`,
          value: res.id,
          name: res.name,
          person: res.person,
          type: res.type,
          id: res.id,
        }));
      },
      attrs: {
        async: true,
        placeholder: 'Type a keyword to search',
      }
    },
    {
      type: 'radio',
      options: Object.keys(Platforms)
        .map(currentKey => Platforms[currentKey].readableName),
      label: 'Platform',
      isPlatform: true,
      attrs: {
        disabled: !FeatureFlagsModule.gcpEnabled
      },
      value: Platforms['AZURE'].readableName,
      hide: !FeatureFlagsModule.azureADEnabled || !FeatureFlagsModule.pingEnabled,
      key: 'platformType',
      inline: false,
      leadingHeader: '',
      required: true,
      validation: {
        rules: {
          required: true,
        },
      },
    },
    {
      type: 'radio',
      options: Object.keys(AUTH_SRC)
      .filter(currentKey => currentKey !== 'API_KEY')
        .map(currentKey => AUTH_SRC[currentKey].readableName),
      label: 'OAuth Source',
      value: FeatureFlagsModule.pingEnabled
        ? 'PingFederate (OAuth)'
        : 'Azure Active Directory (OAuth)',
      hide: !FeatureFlagsModule.azureADEnabled || !FeatureFlagsModule.pingEnabled,
      key: 'oauthType',
      inline: false,
      leadingHeader: '',
      required: true,
      validation: {
        rules: {
          required: true,
        },
      },
    },
  ];
}
